import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
//
import Home1 from '../assets/img/house1.jpeg'
import Home2 from '../assets/img/house2.jpeg'
import Home3 from '../assets/img/house3.jpeg'
import Home4 from '../assets/img/house4.jpeg'
import Home5 from '../assets/img/house5.jpeg'
import Home6 from '../assets/img/house6.jpeg'

import Interline from '../assets/interline.png'
import Interline2 from '../assets/interline2.png'

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "orange", borderRadius: "20px"}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "orange", borderRadius: "20px" }}
      onClick={onClick}
    />
  );
}

export  class CarouselImage extends Component {
  // MULTIPLE CAROUSEL
    render() {
    const settings = {
      className: "center h-[450px] mx-4",
      centerMode: true,
      infinite: true,
      centerPadding: "8px",
      slidesToShow: 3,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };

    const settings2 = {
      className: "center h-[460px] mx-4",
      centerMode: true,
      infinite: true,
      centerPadding: "0px",
      slidesToShow: 1,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };

    return (
      <div className="h-[600px] opacity-100 ">
        {/*********|INTERLINE|************************************************************************************ */} 
        <div className='flex'>
              <img src= {Interline} alt='no encontrado' className='h-[100px] overflow-hidden hidden md:block'/>  
              <img src= {Interline} alt='no encontrado' className='h-[100px] overflow-hidden hidden md:block'/>  
              <img src= {Interline} alt='no encontrado' className='h-[50px] overflow-hidden md:hidden'/>  
          </div> 
{/****************|DESKTOP|*********************************************************************************** */}
        <div className=" lg:p-4 hidden md:block bg-secondary">
          <h2 className="carousellogo-ttle uppercase text-white"></h2>
          <Slider {...settings}>

            <div className=" ">
              <img src={Home1} alt="none" className=" h-[400px] w-[700px]  rounded-3xl p-2"/>
            </div>

            <div className="">
              <img src={Home2} alt="none" className="h-[400px] w-[700px]  rounded-3xl p-2"/>
            </div>

            <div className="">
              <img src={Home3} alt="none" className="h-[400px] w-[700px]  rounded-3xl p-2"/>
            </div>

            <div className="">
              <img src={Home4} alt="none" className="h-[400px] w-[700px]  rounded-3xl p-2"/>
            </div>

            <div className="">
              <img src={Home5} alt="none" className="h-[400px] w-[700px] rounded-3xl p-2"/>
            </div>

            <div className="">
              <img src={Home6} alt="none" className="h-[400px] w-[700px] rounded-3xl p-2"/>
            </div>
          </Slider>
        </div>

{/****************|MOBILE|*********************************************************************************** */}
        <div className="md:hidden bg-secondary min-h-screen pt-6">
          <div className="px-6">
            <h2 className="carousellogo-ttle uppercase text-white"></h2>
            <Slider {...settings2}>
            <div className=" ">
                <img src={Home1} alt="none" className=" h-[430px] w-[500px]  rounded-3xl"/>
              </div>

              <div className="">
                <img src={Home2} alt="none" className="h-[430px] w-[700px]  rounded-3xl"/>
              </div>

              <div className="">
                <img src={Home3} alt="none" className="h-[430px] w-[700px]  rounded-3xl"/>
              </div>

              <div className="">
                <img src={Home4} alt="none" className="h-[430px] w-[700px]  rounded-3xl"/>
              </div>

              <div className="">
                <img src={Home5} alt="none" className="h-[430px] w-[700px] rounded-3xl"/>
              </div>

              <div className="">
                <img src={Home6} alt="none" className="h-[430px] w-[700px] rounded-3xl"/>
              </div>
            </Slider>
          </div>
        </div> 
      </div> 
    );
  } 
}
