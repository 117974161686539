// LIBRERIAS
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// IMPORTANDO VARIABLES
import {useAuth} from '../context/authContext'
import { Alert } from "../comp/Alert";

export function RecoveryPass() {

        // FUNCION USUARIO
        const [user, setUser] = useState({
            email: '',
        });
    
        // FUNCIONES DE VALIDACIONES
        const {login} = useAuth()
        const navigate = useNavigate()
        const [error, setError] = useState();
    
        // GUARDA LA INFORMACION DEL LOS CAMPOS DEL FORMULARIO.
        const handleChange = ({target: {name, value}}) =>
            setUser({...user, [name]: value})
        
    
        // GUARDA LA INFORMACION DEL FORMULARIO CUNADO SE ENVIA.
        const handleSubmit = async (e) => {
            e.preventDefault()
            setError('')
            // PRUEBA DE ERRORES
            try {
                await login(user.email, user.password)
                navigate('/')
    
            } catch(error) {
                setError(error.message);
            }
        };
        
        //
        const handleResetPass = async() => {
            if (!user.email) return ( setError("Porfavor ingrese su correo"));
            try {
                await handleResetPass(user.email)
                setError('Hemos enviado un correo para el cambio')
            }catch(error) {
                setError(error.message);
            }
        }

    return (
        <div className="w-full max-w-xs m-auto">
            
            {/*|Mensaje de error desde firebase|*******************/}
                    {error && <Alert message={error} />}
            {/*|Formulario de registro|*****************/}
                    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2"> Email</label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="email" 
                                name="email"
                                id="email" 
                                placeholder="tucorreo@gmail.com" 
                                autoComplete="text" 
                                onChange={handleChange}
                                required />
                        </div>

                        <div className="flex items-center justify-between">
                            <button onClick={handleResetPass} className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Enviar</button>
                        </div>
                    </form>
        </div>
    );
};