import React, { useState } from "react";
//
import { Link } from 'react-router-dom';
import ReactPlayer from "react-player";

//
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
/* Install pure-react-carousel using -> npm i pure-react-carousel */

export function CarouselVideo() {
  const [play, setPlay] = useState(false);
  const [play1, setPlay1] = useState(false);
  const [play2, setPlay2] = useState(false);
  const [play3, setPlay3] = useState(false);
  const [play4, setPlay4] = useState(false);
  const [play5, setPlay5] = useState(false);
  const [play6, setPlay6] = useState(false);
  const [play7, setPlay7] = useState(false);
  const [play8, setPlay8] = useState(false);

  const handleMouseEnter = () => {
    setPlay(true);
  };
  const handleMouseLeave = () => {
    setPlay(false);
  };

  //
  const handleMouseEnter1 = () => {
    setPlay1(true);
  };
  const handleMouseLeave1 = () => {
    setPlay1(false);
  };
  //
  const handleMouseEnter2 = () => {
    setPlay2(true);
  };
  const handleMouseLeave2 = () => {
    setPlay2(false);
  };
  //
  const handleMouseEnter3 = () => {
    setPlay3(true);
  };
  const handleMouseLeave3 = () => {
    setPlay3(false);
  };
  //
  const handleMouseEnter4 = () => {
    setPlay4(true);
  };
  const handleMouseLeave4 = () => {
    setPlay4(false);
  };
  // 5
  const handleMouseEnter5 = () => {
    setPlay5(true);
  };
  const handleMouseLeave5 = () => {
    setPlay5(false);
  };

  // 6
  const handleMouseEnter6 = () => {
    setPlay6(true);
  };
  const handleMouseLeave6 = () => {
    setPlay6(false);
  };
  //
  const handleMouseEnter7 = () => {
    setPlay7(true);
  };
  const handleMouseLeave7 = () => {
    setPlay7(false);
  };
  //
  const handleMouseEnter8 = () => {
    setPlay8(true);
  };
  const handleMouseLeave8 = () => {
    setPlay8(false);
  };
  
  return (
    <div className="bg-primary mb-4">
    {/************ PARTE 1 - TITULO CAROUSEL ****************/}
      <h2 className="carousel-ttle">
          Quieres recorrer los inmuebles disponibles?, <Link to="https://www.youtube.com/@vyaokcorredores3613"><span className="text-tertiary ">Haz click en los videos</span></Link>
      </h2>
    {/************************************** PARTE 2 - CAROUSEL **************************************************/}
      <div className=" md:ml-20">
        {/* desktop  & laptop*/}
        <div className="carousel-container-pc">   
          {/* Opciones Carousel*/}
          <CarouselProvider
            className="md:flex flex-row hidden w-full"
            isIntrinsicHeight={true}
            totalSlides={2}
            infinite={true}
          >
            <div className="w-full relative flex items-center justify-center">
            {/* A */}
              <ButtonBack
                role="button"
                aria-label="slide backward"
                className="carousel-flecha-izq"
                id="prev"
              >
                <svg
                  width={16}
                  height={28}
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 1L1 7L7 13"
                    stroke="blue"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ButtonBack>

            {/* B */}
            
              <div className="md:mx-10 lg:pl-0 xl:ml-5 overflow-x-hidden overflow-y-hidden">
                <Slider>
                  <div  
                    id="slider"
                    className="carouselvideo-slider"
                  >

  {/*------------------------------ 0 --------------------------------*/}
                    <Slide index={0}>
                    <div
                        className="crdblanca-txtblanco player-wrapper"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        <ReactPlayer
                          className=" react-player rounded-2xl overflow-y-hidden"
                          width="100%"
                          height="100%"
                          playing={play}
                          muted={true}
                          controls={false}
                          url="https://www.youtube.com/watch?v=0j9nWUGOgQ8"
                        />
                      </div>
                    </Slide>

  {/*------------------------------ 1 --------------------------------*/}
                    <Slide index={1}>
                    <div
                        className="crdblanca-txtblanco player-wrapper"
                        onMouseEnter={handleMouseEnter1}
                        onMouseLeave={handleMouseLeave1}
                      >
                      <ReactPlayer
                          className="react-player rounded-2xl overflow-y-hidden"
                          width="100%"
                          height="100%"
                          playing={play1}
                          muted={true}
                          pip
                          controls={false}
                          config={{ file: { forceHLS: true } }}
                          url="https://www.youtube.com/watch?v=1NbN8HK_2u0"
                        />
                      </div>
                    </Slide>

  {/*------------------------------ 2 --------------------------------*/}
                    <Slide index={2}>
                    <div
                        className="crdblanca-txtblanco player-wrapper"
                        onMouseEnter={handleMouseEnter2}
                        onMouseLeave={handleMouseLeave2}
                      >
                        <ReactPlayer
                          className=" react-player rounded-2xl overflow-y-hidden"
                          width="100%"
                          height="100%"
                          playing={play2}
                          muted={true}
                          pip
                          controls={false}
                          config={{ file: { forceHLS: true } }}
                          url="https://www.youtube.com/watch?v=_uRpiOEL1SA"
                        />
                      </div>
                    </Slide>

  {/*------------------------------ 3 --------------------------------*/}
                    <Slide index={3}>
                    <div
                        className="crdblanca-txtblanco player-wrapper"
                        onMouseEnter={handleMouseEnter3}
                        onMouseLeave={handleMouseLeave3}
                      >
                        <ReactPlayer
                          className=" react-player rounded-2xl overflow-y-hidden"
                          width="100%"
                          height="100%"
                          playing={play3}
                          muted={true}
                          pip
                          controls={false}
                          config={{ file: { forceHLS: true } }}
                          url="https://www.youtube.com/watch?v=NBtjpc0VVOY"
                        />
                      </div>
                    </Slide>

  {/*------------------------------ 4 --------------------------------*/}
                    <Slide index={4}>
                    <div
                        className="crdblanca-txtblanco player-wrapper"
                        onMouseEnter={handleMouseEnter4}
                        onMouseLeave={handleMouseLeave4}
                      >
                        <ReactPlayer
                          className=" react-player rounded-2xl overflow-y-hidden"
                          width="100%"
                          height="100%"
                          playing={play4}
                          muted={true}
                          pip
                          controls={false}
                          config={{ file: { forceHLS: true } }}
                          url="https://www.youtube.com/watch?v=ESvd2DnASuk"
                        />
                      </div>
                    </Slide>

  {/*------------------------------ 5 --------------------------------*/}
                      <Slide index={5}>
                    <div
                        className="crdblanca-txtblanco player-wrapper"
                        onMouseEnter={handleMouseEnter5}
                        onMouseLeave={handleMouseLeave5}
                      >
                        <ReactPlayer
                          className=" react-player rounded-2xl overflow-y-hidden"
                          width="100%"
                          height="100%"
                          playing={play5}
                          muted={true}
                          pip
                          controls={false}
                          config={{ file: { forceHLS: true } }}
                          url="https://www.youtube.com/watch?v=kvcV8MP_ncQ"
                        />
                      </div>
                    </Slide>

  {/*------------------------------ 6 --------------------------------*/}
                      <Slide index={6}>
                    <div
                        className="crdblanca-txtblanco player-wrapper"
                        onMouseEnter={handleMouseEnter6}
                        onMouseLeave={handleMouseLeave6}
                      >
                        <ReactPlayer
                          className=" react-player rounded-2xl overflow-y-hidden"
                          width="100%"
                          height="100%"
                          playing={play6}
                          muted={true}
                          pip
                          controls={false}
                          config={{ file: { forceHLS: true } }}
                          url="https://www.youtube.com/watch?v=IJP2GsHLrfc"
                        />
                      </div>
                    </Slide>

  {/*------------------------------ 7 --------------------------------*/}
                      <Slide index={7}>
                    <div
                        className="crdblanca-txtblanco player-wrapper"
                        onMouseEnter={handleMouseEnter7}
                        onMouseLeave={handleMouseLeave7}
                      >
                        <ReactPlayer
                          className=" react-player rounded-2xl overflow-y-hidden"
                          width="100%"
                          height="100%"
                          playing={play7}
                          muted={true}
                          pip
                          controls={false}
                          config={{ file: { forceHLS: true } }}
                          url="https://www.youtube.com/watch?v=2FR0XxbVA7A"
                        />
                      </div>
                    </Slide>

  {/*------------------------------ 8 --------------------------------*/}
                      <Slide index={8}>
                    <div
                        className="crdblanca-txtblanco player-wrapper"
                        onMouseEnter={handleMouseEnter8}
                        onMouseLeave={handleMouseLeave8}
                      >
                        <ReactPlayer
                          className=" react-player rounded-2xl overflow-y-hidden"
                          width="100%"
                          height="100%"
                          playing={play8}
                          muted={true}
                          pip
                          controls={false}
                          config={{ file: { forceHLS: true } }}
                          url="https://www.youtube.com/watch?v=UEwDFAhYtV0"
                        />
                      </div>
                    </Slide>
                  </div>
                </Slider>
              </div>
              

              {/* C */}
              <ButtonNext
                role="button"
                aria-label="slide forward"
                className="carousel-flecha-der"
                id="next"
              >
                <svg
                  width={16}
                  height={28}
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7 7L1 13"
                    stroke="blue"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ButtonNext>
            </div>
          </CarouselProvider>
        </div>

{/******************************************************************************* */}

        {/* mobile */}
        <div className="md:hidden flex ">
          {/* Opciones Carousel*/}
          <CarouselProvider
            className="md:hidden w-full"
            naturalSlideWidth={200}
            isIntrinsicHeight={true}
            totalSlides={9}
            visibleSlides={1}
            step={1}
            infinite={true}
          >
            <div className="w-full relative flex items-center justify-center">

            {/* A */}
              <ButtonBack
                role="button"
                aria-label="slide backward"
                className="carousel-flecha-izq-cel"
                id="prev"
              >
                <svg
                  width={16}
                  height={28}
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 1L1 7L7 13"
                    stroke="blue"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ButtonBack>

            {/* B */}
              <div className="w-[270px] h-[400px] ">
                <Slider>
                  <div  
                    id="slider"
                    className="w-full duration-700"
                  >
                    {/*---------- 0 -------*/}
                    <Slide index={0}>
                    <div
                        className="crdblanca-txtblanco-cel "
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        <ReactPlayer
                          className="crdblanca-opt-cel"
                          width="400px"
                          height="400px"
                          playing={play}
                          muted={true}
                          pip
                          controls={false}
                          config={{ file: { forceHLS: true } }}
                          url="https://www.youtube.com/watch?v=0j9nWUGOgQ8"
                        />
                      </div>
                    </Slide>

                    {/*---------- 1 -------*/}
                    <Slide index={1}>
                    <div
                        className="crdblanca-txtblanco-cel"
                        onMouseEnter={handleMouseEnter1}
                        onMouseLeave={handleMouseLeave1}
                      >
                      <ReactPlayer
                          className="crdblanca-opt-cel"
                          width="400px"
                          height="400px"
                          playing={play1}
                          muted={true}
                          pip
                          controls={false}
                          config={{ file: { forceHLS: true } }}
                          url="https://www.youtube.com/watch?v=1NbN8HK_2u0"
                        />
                      </div>
                    </Slide>

                    {/*---------- 2 -------*/}
                    <Slide index={2}>
                    <div
                        className="crdblanca-txtblanco-cel"
                        onMouseEnter={handleMouseEnter2}
                        onMouseLeave={handleMouseLeave2}
                      >
                      <ReactPlayer
                          className="crdblanca-opt-cel"
                          width="400px"
                          height="400px"
                          playing={play2}
                          muted={true}
                          pip
                          controls={false}
                          config={{ file: { forceHLS: true } }}
                          url="https://www.youtube.com/watch?v=_uRpiOEL1SA"
                        />
                      </div>
                    </Slide>

                    {/*---------- 3 -------*/}
                    <Slide index={3}>
                    <div
                        className="crdblanca-txtblanco-cel"
                        onMouseEnter={handleMouseEnter3}
                        onMouseLeave={handleMouseLeave3}
                      >
                      <ReactPlayer
                          className="crdblanca-opt-cel"
                          width="400px"
                          height="400px"
                          playing={play3}
                          muted={true}
                          pip
                          controls={false}
                          config={{ file: { forceHLS: true } }}
                          url="https://www.youtube.com/watch?v=NBtjpc0VVOY"
                        />
                      </div>
                    </Slide>

                    {/*---------- 4 -------*/}
                    <Slide index={4}>
                    <div
                        className="crdblanca-txtblanco-cel"
                        onMouseEnter={handleMouseEnter4}
                        onMouseLeave={handleMouseLeave4}
                      >
                      <ReactPlayer
                          className="crdblanca-opt-cel"
                          width="400px"
                          height="400px"
                          playing={play4}
                          muted={true}
                          pip
                          controls={false}
                          config={{ file: { forceHLS: true } }}
                          url="https://www.youtube.com/watch?v=ESvd2DnASuk"
                        />
                      </div>
                    </Slide>

                    {/*---------- 5 -------*/}
                    <Slide index={5}>
                    <div
                        className="crdblanca-txtblanco-cel"
                        onMouseEnter={handleMouseEnter5}
                        onMouseLeave={handleMouseLeave5}
                      >
                      <ReactPlayer
                          className="crdblanca-opt-cel"
                          width="400px"
                          height="400px"
                          playing={play5}
                          muted={true}
                          pip
                          controls={false}
                          config={{ file: { forceHLS: true } }}
                          url="https://www.youtube.com/watch?v=kvcV8MP_ncQ"
                        />
                      </div>
                    </Slide>

                    {/*---------- 6 -------*/}
                    <Slide index={6}>
                    <div
                        className="crdblanca-txtblanco-cel"
                        onMouseEnter={handleMouseEnter6}
                        onMouseLeave={handleMouseLeave6}
                      >
                      <ReactPlayer
                          className="crdblanca-opt-cel"
                          width="400px"
                          height="400px"
                          playing={play6}
                          muted={true}
                          pip
                          controls={false}
                          config={{ file: { forceHLS: true } }}
                          url="https://www.youtube.com/watch?v=IJP2GsHLrfc"
                        />
                      </div>
                    </Slide>

                    {/*---------- 7 -------*/}
                    <Slide index={7}>
                    <div
                        className="crdblanca-txtblanco-cel"
                        onMouseEnter={handleMouseEnter7}
                        onMouseLeave={handleMouseLeave7}
                      >
                      <ReactPlayer
                          className="crdblanca-opt-cel"
                          width="400px"
                          height="400px"
                          playing={play7}
                          muted={true}
                          pip
                          controls={false}
                          config={{ file: { forceHLS: true } }}
                          url="https://www.youtube.com/watch?v=2FR0XxbVA7A"
                        />
                      </div>
                    </Slide>

                    {/*---------- 8 -------*/}
                    <Slide index={8}>
                    <div
                        className="crdblanca-txtblanco-cel"
                        onMouseEnter={handleMouseEnter8}
                        onMouseLeave={handleMouseLeave8}
                      >
                      <ReactPlayer
                          className="crdblanca-opt-cel"
                          width="400px"
                          height="400px"
                          playing={play8}
                          muted={true}
                          pip
                          controls={false}
                          config={{ file: { forceHLS: true } }}
                          url="https://www.youtube.com/watch?v=UEwDFAhYtV0"
                        />
                      </div>
                    </Slide>
                  </div>
                </Slider>
              </div>

              {/* C */}
              <ButtonNext
                role="button"
                aria-label="slide forward"
                className="carousel-flecha-der-cel"
                id="next"
              >
                <svg
                  width={16}
                  height={28}
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7 7L1 13"
                    stroke="blue"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ButtonNext>

            </div>
          </CarouselProvider>
        </div>
      </div>
    </div>
  );
}
