import React from 'react'

export function UserProfile() {
    return (
        <div className='w-full max-w-xs m-auto'>
    
    {/*****************/}
        </div>
    )
}
