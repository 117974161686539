import React from "react";
import "pure-react-carousel/dist/react-carousel.es.css";

import Interline from '../assets/interline.png'
import Interline2 from '../assets/interline2.png'

// import house images
import Logo1 from '../assets/img/70c.png';
import Logo2 from '../assets/img/abogados.png';
import Logo3 from '../assets/img/breal.png';
import Logo4 from '../assets/img/serco.png';
import Logo5 from '../assets/img/ctb.png';




/* Install pure-react-carousel using -> npm i pure-react-carousel */

const LOGOS = [
  <img src={Logo1} />,
  <img src={Logo2} />,
  <img src={Logo3} />,
  <img src={Logo4} />,
  <img src={Logo5} className="h-[150px] "/>,
  
];

export function CarouselLogo() {
  return (
    <section className="bg-white ">
{/*********|INTERLINE|************************************************************************************ */} 
      <div className='flex'>
          <img src= {Interline2} alt='no encontrado' className='h-[100px] overflow-hidden hidden md:block'/>  
          <img src= {Interline2} alt='no encontrado' className='h-[100px] overflow-hidden hidden md:block'/>  
          <img src= {Interline2} alt='no encontrado' className='h-[50px] overflow-hidden md:hidden'/>  
      </div> 
      
{/*********|CAROUSEL LOGO|************************************************************************************/}
    <div className="relative m-auto w-full overflow-hidden bg-white before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[100px] before:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[100px] after:-scale-x-100 after:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] after:content-['']">
      <div className="animate-infinite-slider flex w-[calc(250px*10)]">
        {LOGOS.map((logo, index) => (
          <div
            className="slide flex w-[300px] items-center justify-center"
            key={index}>
            {logo}
          </div>
        ))}
        {LOGOS.map((logo, index) => (
          <div
            className="slide flex w-[300px] items-center justify-center"
            key={index}>
            {logo}
          </div>
        ))}
      </div>
    </div>
    </section>
  );
}
