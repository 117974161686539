import React from 'react';
//
import {NavbarLogo} from "../comp/NavbarLogo";
import {HeroImage} from '../comp/HeroImage';
import {CarouselVideo} from '../comp/Video/CarouselVideo'
import { CarouselLogo } from '../comp/CarouselLogo';
import {CarouselImage} from '../comp/CarouselImage';
import { Footer } from '../comp/Footer';


export function Main (){
    return (
        <>
            <NavbarLogo />
            <HeroImage />
            <CarouselVideo />
            <CarouselImage />
            <CarouselLogo />
            <Footer /> 
        </>
    )
}