// LIBRERIAS
import { useState } from "react";
import { useNavigate} from "react-router-dom";
// IMPORTANDO VARIABLES
import {useAuth} from '../context/authContext'
import { Alert } from "../comp/Alert";

//
export function Login() {
    // FUNCION USUARIO
    const [user, setUser] = useState({
        email: '',
        password: '',
    });

    // FUNCIONES DE VALIDACIONES
    const {login, loginWithGoogle} = useAuth()
    const navigate = useNavigate()
    const [error, setError] = useState();

    // GUARDA LA INFORMACION DEL LOS CAMPOS DEL FORMULARIO.
    const handleChange = ({target: {name, value}}) =>
        setUser({...user, [name]: value})
    

    // GUARDA LA INFORMACION DEL FORMULARIO CUNADO SE ENVIA.
    const handleSubmit = async (e) => {
        e.preventDefault()
        setError('')
        // PRUEBA DE ERRORES
        try {
            await login(user.email, user.password)
            navigate('/')

        } catch(error) {
            setError(error.message);
        }
    };


    //  FUNCION PARA LOGEARSE CON GOOGLE
    const handleGoogleSignin = async() => {
        try{
            await loginWithGoogle()
            navigate('/')
        }catch(error){
            setError(error.message)
        }
    };

    // RETORNO VISUAL
    return (
        <div className="flex w-full bg-gray-400 p-2">
        {/*|Mensaje de error desde firebase|*******************/}
                {error && <Alert message={error} />}

        {/*|Formulario de registro|*****************/}
                <div className="flex-row  w-full bg-blue-300">
                    <form onSubmit={handleSubmit} className="lg:w-[20%] lg:ml-[40%] h-[30%] bg-white shadow-md rounded mt-[10%] ml-[10%] mr-[10%] px-8 pt-6 pb-8 mb-4  ">
                    {/********/}
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2"> Email</label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="email" 
                                name="email"
                                id="email" 
                                placeholder="tucorreo@gmail.com"
                                autoComplete="text" 
                                onChange={handleChange}
                                required />
                        </div>

                    {/********/}
                        <div className="mb-4">
                            <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2"> Password</label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                                type="password"
                                name="password"
                                id="password" 
                                placeholder="*******"
                                autoComplete="off"
                                onChange={handleChange}
                                required />
                        </div>

                    {/********/}
                        <div className="flex items-center justify-between">
                            <button className="bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Login</button>
                            <a href="/recoverypass" className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800 px-2">Olvidaste tu contraseña?</a>
                        </div>
                    </form>

        {/*|BOTON LOGIN CON GOOGLE|*****************/}
                    <button 
                        onClick={handleGoogleSignin} 
                        className="lg:w-[20%] lg:ml-[40%] h-[50px] bg-slate-50 hover:bg-slate-200 text-black shadow-md rounded border-2 border-gray-300 ml-[10%] w-[80%] py-2 px-4"
                        >Google
                    </button>

                </div>
            </div>
    );
};
