import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Button from './Button';
import "./navbarStyles.css";


export function  NavbarLogo () {
        let Links =[
        {name:"HOME",link:"/"},
        {name:"SERVICE",link:"/"},
        {name:"ABOUT",link:"/"},
        {name:"BLOG'S",link:"/"},
        {name:"CONTACT",link:"/"},
        ];
    let [open,setOpen]=useState(false);
    
    return (
        <div className='bg-white lg:bg-primary/90 flex flex-col lg:flex-row sticky top-0 z-50'>

            <div className='lg:flex items-center justify-between  py-4 lg:px-10 px-7 w-full'>
                {/************ PARTE 1 - LOGO ****************/}
                <div to="/">
                    <div className="ttle-logo">
                    <p>
                        VYA
                        <span className="ttle-logo-change">O</span>K
                    </p>
                    </div>
                </div>
            
                <div onClick={()=>setOpen(!open)} className='text-3xl absolute right-8 top-6 cursor-pointer lg:hidden'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                </div>

                <ul className={`bg-white sm:bg-primary/0 lg:flex lg:items-center lg:pb-0 pb-12 absolute lg:static lg:z-auto z-[-1] left-0 w-full lg:w-auto lg:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-18 ':'top-[-490px]'}`}>
                    {/* {
                    Links.map((link)=>(
                        <li key={link.name} className='text-center mr-8 font-bold'>
                            <hr className="w-48 h-1 mx-auto my-2 border-0 rounded lg:hidden bg-tertiary"></hr>
                        <Link href={link.link} className='text-secondary hover:text-tertiary duration-500 '>{link.name}</Link>
                        </li>
                    ))
                    } */}
                    <div className='grid -ml-8 lg:-ml-5 lg:flex'>
                        <Link to="https://btg-3241.my.salesforce-sites.com/propietarios/"className="navbar-boton">
                            <Button>
                                Propietarios
                            </Button>
                        </Link>
                        
                        <Link to="https://btg-3241.my.salesforce-sites.com/arrendatarios/" className="navbar-boton">
                            <Button>
                                Arrendatarios
                            </Button>
                        </Link>

                        <Link to="https://btg-3241.my.site.com/s/" className="navbar-boton">
                            <Button>
                                Propiedades
                            </Button>
                        </Link>
                    </div>
                </ul>
            </div>
        </div>
    )
}
