// Importa las funciones que necesitas de los SDK que necesitas
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import { getStorage } from "firebase/storage";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAK-bpm2yufGQotof389PsKwQvR2NUG-2k",
  authDomain: "ctbytes-1c79f.firebaseapp.com",
  projectId: "ctbytes-1c79f",
  storageBucket: "ctbytes-1c79f.appspot.com",
  messagingSenderId: "839322314560",
  appId: "1:839322314560:web:25a180ffb3f50eda783ca5",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

//CRUD y Files
export const db = getFirestore(app);
export const storage = getStorage(app);