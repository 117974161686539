// LIBRERIAS
import { Routes,Route, Navigate } from "react-router-dom";
import {AuthProvider} from "./context/authContext"
import { ProtectedRoute } from "./context/ProtectedRoute";

// IMPORTANDO VARIABLES
import {Login} from "./page/Login"
import {Register} from "./page/Register"
import { RecoveryPass } from "./page/RecoverPass";
import { UserProfile } from "./page/UserProfile";
import { Main } from "./page/Main";

//import { Page404 } from "./page/Page404";

//import { WebsiteList } from "./comp/WebsiteList";
//import { WebsiteForm } from "./comp/WebsiteForm";
//import  Layout  from "./comp/Layout";

//
function App() {

  // RUTAS DENTRO DE LA APP
  return(
    <div className="">
      <AuthProvider>
        <Routes>
          <Route path="/*" element={<Navigate to="/" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/recoverypass" element={<RecoveryPass />} />
          <Route path="/" element={<Main />} />

          <Route path="/profile" element={
            <ProtectedRoute>
              <UserProfile />
            </ProtectedRoute>
          } />

        </Routes>
      </AuthProvider>
    </div>
  )
}

export default App;