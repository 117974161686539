import React from 'react'

const Button = (props) => {
    return (
        <button className='navbar-boton bg-tertiary text-secondaryx font-[Poppins] py-2 px-6 rounded-full hover:bg-tertiary/50 hover:text-white
        duration-500'>
            {props.children}
        </button>
    )
}

export default Button