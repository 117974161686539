import React from "react";
import { Link } from 'react-router-dom';

export  function HeroImage() {
  return (
    <div className="w-full font-body">
      <section className="md:flex ">
        
{/************* PARTE 1 - IMAGEN IZQUIERDA ****************/}
        <div className="hero-img-izq-cel md:hero-img-izq bg-center">
          <h1 className="hero-ttle">Bienvenido a</h1>
          <h1 className="hero-subttle "> VYAOK SPA</h1>

          {/* boton hero celular*/}
          <div className="">
            <Link to="https://btg-3241.my.salesforce-sites.com/propietarios/"className=" hero-boton">
                Si eres propietario, haz click aqui
            </Link>
          </div>
        </div>

{/************* PARTE 2 - IMAGEN DERECHA ****************/}
        <div className="hero-img-der-cel md:hero-img-der bg-center"></div>
      </section>
    </div>
  );
}
